import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React from "react";
import { Notification as NotificationType } from "../../generated";
import { useOpenClose } from "../hooks/useOpenClose";

type NotificationProps = {
  notification: NotificationType;
};

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const [open, { handleClose }] = useOpenClose(true);

  const { display, start, end, severity, closeable, hideTitle, title, message } = notification;

  if (display === "timed") {
    const after = new Date(start);
    const before = new Date(end);
    const today = new Date();

    if (today > before || today < after) {
      return null;
    }
  }

  return (
    <Collapse in={open}>
      <Alert
        severity={severity}
        action={
          closeable ? (
            <IconButton onClick={handleClose} size="small">
              <CloseRoundedIcon />
            </IconButton>
          ) : null
        }
        sx={closeable ? { mb: 2 } : {}}
      >
        {!hideTitle && <AlertTitle>{title}</AlertTitle>}
        <Typography variant="body2" component="pre">
          {message}
        </Typography>
      </Alert>
    </Collapse>
  );
};

export default Notification;
