import { useLatestStatsQuery } from "@/generated";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import DataBox from "./generic/dataBox";

type LatestStatsProps = {};

const LatestStats: React.FC<LatestStatsProps> = () => {
  const { data, loading } = useLatestStatsQuery();

  return (
    <DataBox loading={loading} header="Statistieken van de afgelopen maand">
      {data?.authenticatedItem?.projects?.map((project) => (
        <Fragment key={project.id}>
          {project.statsByMonth?.map((stats) => (
            <div key={`${stats.month}${stats.year}`}>
              <Typography component="p" variant="body2" gutterBottom align="right">
                <em>
                  {stats.month}-{stats.year}
                </em>
              </Typography>
              <Typography component="p" variant="body1" gutterBottom>
                aantal rebuilds: &emsp; {stats.runs}
              </Typography>

              <Divider variant="middle" sx={{ my: 2 }} />

              <Typography component="p" variant="body1" gutterBottom>
                aantal minuten: &emsp; {stats.minutes}
              </Typography>
              <Typography component="p" variant="body1" gutterBottom>
                gemiddeld: &emsp;&emsp;&emsp; {stats.avgMinutes}
              </Typography>

              <Divider variant="middle" sx={{ my: 2 }} />

              <Typography component="p" variant="body1" gutterBottom>
                minuten p/m: &emsp;&emsp; {project.buildMinutes}
              </Typography>
              <Typography component="p" variant="body1" gutterBottom>
                minuten over: &emsp;&emsp; {stats.minutesLeftThisMonth}
              </Typography>
            </div>
          ))}
        </Fragment>
      ))}

      {data?.authenticatedItem?.projects?.length === 0 && (
        <Typography component="p" variant="body1" gutterBottom textAlign="center">
          Helaas is er nog geen informatie beschikbaar
        </Typography>
      )}
    </DataBox>
  );
};

export default LatestStats;
