import { useProjectsQuery } from "@/generated";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import BaseLink from "./generic/baseLink";
import DataBox from "./generic/dataBox";

type ProjectInfoProps = {};

const ProjectInfo: React.FC<ProjectInfoProps> = () => {
  const { data, loading } = useProjectsQuery();

  return (
    <DataBox loading={loading} header="Algemene info">
      {data?.authenticatedItem?.projects?.map((project) => (
        <Fragment key={project.id}>
          <Typography component="p" variant="body2" gutterBottom>
            start hosting: &emsp;&emsp;&emsp;&emsp;&emsp;
            {new Date(project.startHosting).toLocaleDateString("nl-NL", { dateStyle: "medium" })}
          </Typography>
          <Typography component="p" variant="body2" gutterBottom>
            website: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{" "}
            <BaseLink align="center" href={project.website} target="_blank">
              {project.website}
            </BaseLink>
          </Typography>
          <Typography component="p" variant="body2" gutterBottom>
            aantal domeinen: &emsp;&emsp;&emsp; {project.numberOfDomains}
          </Typography>
          <Typography component="p" variant="body2" gutterBottom>
            totaal aantal rebuilds: &emsp; {project.runsCount}
          </Typography>
        </Fragment>
      ))}

      {data?.authenticatedItem?.projects?.length === 0 && (
        <Typography component="p" variant="body1" gutterBottom textAlign="center">
          Helaas is er nog geen informatie beschikbaar
        </Typography>
      )}
    </DataBox>
  );
};

export default ProjectInfo;
