import FloatingNew from "@/components/floatingNew";
import Header from "@/components/generic/header";
import Notification from "@/components/generic/notification";
import { useInterval } from "@/components/hooks/useInterval";
import LatestStats from "@/components/latestStats";
import Layout from "@/components/layout";
import ProjectInfo from "@/components/projectInfo";
import TicketBoxLink from "@/components/ticketBoxLink";
import { useGetLatestTicketQuery, useNotificationsQuery, useUserQuery } from "@/generated";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Link, PageProps } from "gatsby";
import React from "react";

const HomePage: React.FC<PageProps> = () => {
  const { data } = useUserQuery();
  const { data: tickets, loading } = useGetLatestTicketQuery();
  const { data: notificationsQuery, refetch } = useNotificationsQuery();

  const { notifications } = { ...notificationsQuery };

  useInterval(async () => await refetch(), 5000);

  return (
    <Layout title="Mijn MDDD">
      {data?.authenticatedItem && (
        <Container maxWidth="lg" sx={{ paddingX: 0 }}>
          <Header>Welkom {data.authenticatedItem.displayName}!</Header>

          {notifications && (
            <Container maxWidth="md" sx={{ display: "flex", flexDirection: "column", gap: 1, paddingBottom: 2 }}>
              {notifications.map((notification) => (
                <Notification notification={notification} />
              ))}
            </Container>
          )}

          <Container
            maxWidth="lg"
            sx={{ paddingX: 0, display: "flex", gap: 1, flexDirection: { xs: "column", lg: "row" } }}
          >
            <ProjectInfo />
            <LatestStats />
          </Container>

          <Container sx={{ paddingX: 0 }}>
            {tickets && tickets.authenticatedItem.tickets.length > 0 && (
              <>
                <Divider sx={{ marginY: 8 }} variant="middle" />

                <Header variant="h5">Laatste bericht</Header>

                <TicketBoxLink ticket={tickets.authenticatedItem.tickets[0]} loading={false} />

                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 3 }}>
                  <Button variant="text" size="large" color="tertiary" component={Link} to="/berichten/">
                    Alle berichten
                  </Button>
                </Box>
              </>
            )}
          </Container>

          <FloatingNew label="Nieuw bericht" top={3} right={1.5} />
        </Container>
      )}
    </Layout>
  );
};

export default HomePage;
